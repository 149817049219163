import BaseProps from 'o365.controls.BaseLookup.Props.ts';

export default {
    ...BaseProps,
    /** WHen true will not add keyboard shortucts for the lookup */
    suppressKeyboardEvents: {
        type: Boolean,
        default: false,
    },
    autosave: {
        type: Boolean,
        default: false
    },
    height: {
        type: Number,
        default() {
            return window.innerHeight > 800 ? 500 : 300
        },
    },
    maxWidth: Number,
    /**
    * Drop down options
    * @values top, top-start, top-end, left, left-start, left-end, right, right-start, right-end, bottom, bottom-start, bottom-end
    */
    placement: {
        type: String,
        default: 'bottom-start',
    },
    /**
     * Creates an input for the lookup and binds the dropdown target to it
     */
    textInput: {
        type: Boolean,
        default: false
    },
    autoFilterDebounce: {
        type: [Number, Boolean],
        default: 500
    },
    disableAutoFilter: {
        type: Boolean,
        default: false
    },

    /**
     * Field or function to be used when binding to context     
     */
    contextField: {
        type: [String, Function],
        required: false
    },

    /**
     * Text to be displayed on "Limit to context" checkbox label
     * @type String
     */
    contextToggleLabel: {
        type: String,
        required: false
    },
    /** Disable dynamic loading on the grid */
    disableDynamicLoading: {
        type: Boolean,
    },

    /** If set to true, the loookup's Data Object will always reload when lookup is opened */
    forceReloadOnOpen: {
        type: Boolean,
        default: false,
        required: false
    },
    nodeData: {
        type: Object
    },
    /** Field filter that should be focused when opening the lookup. Will default to the first visible column */
    focusField: {
        type: String
    },
    /**
     * Optional selector query that will be used to focus an element on open if defined. 
     * Can be used to focus in custom elements in the lookup toolbar (like a custom search input)
     */
    focusSelector: {
        type: String
    },
    /** Function called right after a lookup was opened and loaded on all items. */
    itemLoaded: {
        type: Function
    },
    /**
     * When enabled will automaticly reload the dataobject on whereClause prop change when the lookup is open
     * @default false
     */
    reloadOnWhereClauseChange: Boolean,
    /** When provided will enable support for persistent filters on the filter object */
    persistentFilterId: String,
    /** Execute bind function when a row is clicked in multiselect mode */
    bindOnSelect: Boolean,
    /** Row class function passed to the inner data grid */
    rowClass: Function
};